import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Inicio from './pages/Inicio';
import Estoque from './pages/Estoque';
import Contato from './pages/Contato';
import Veiculos from './pages/Veiculos';
import Financiamento from './pages/Financiamento';
import VendaSeuVeiculo from './pages/VendaSeuVeiculo';
import VeiculosMaverick from './revendas/Veiculos_Maverick';
import VeiculosPissarelli from './revendas/Veiculos_Pissarelli';
import VeiculosPremium from './revendas/Veiculos_Premium';
import VeiculosAstro from './revendas/Veiculos_Astro';
import VeiculosSpiner from './revendas/Veiculos_Spiner';
import XmlPage from './pages/xmlPage';
import XmlPageIntegracoes from './pages/xmlPageIntegracoes';
import VeiculosGbr from './revendas/Veiculos_Gbr';
import VeiculosLaVoiture from './revendas/Veiculos_LaVoiture';


class App extends Component {
  render(){
    return (
      <Router>
          <Switch>  
              <Route path='/' exact component={Inicio} />  
              <Route path='/veiculos/:idVeiculo' component={Veiculos} />
              <Route path='/veiculo_maverick/:idVeiculo' component={VeiculosMaverick} />
              <Route path='/veiculo_pissarelli/:idVeiculo' component={VeiculosPissarelli} />
              <Route path='/veiculo_premium/:idVeiculo' component={VeiculosPremium} />
              <Route path='/veiculo_gbr/:idVeiculo' component={VeiculosGbr} />
              <Route path='/veiculo_astro/:idVeiculo' component={VeiculosAstro} />
              <Route path='/veiculo_spiner/:idVeiculo' component={VeiculosSpiner} />
              <Route path='/veiculo_lavoiture/:idVeiculo' component={VeiculosLaVoiture} />
              <Route path='/xmlfacebook' component={XmlPage} />
              <Route path='/xmlintegracoes' component={XmlPageIntegracoes} />
              <Route path='/contato' component={Contato} />                  
              <Route path='/financiamento' component={Financiamento} />                  
              <Route path='/estoque' component={Estoque} />                  
              <Route path='/vendaseuveiculo' component={VendaSeuVeiculo} />         
              <Route path='/:idempresa' component={Inicio} />         
          </Switch> 
      </Router>
    );
  }
}

export default App;
